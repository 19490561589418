<template>
	<div>
		<t-modal v-model="isDetailOpen">
			<product-detail
				v-if="selectedProduct"
				:product="selectedProduct"
				@submited="isDetailOpen = false"
			/>
		</t-modal>
		<div v-if="prices.length == 0" class="order-body p-3">
			<div class="p-4 text-grey-500 bg-white text-center shadow rounded">
				aucun produit
			</div>
		</div>
		<div class="order-body p-3">
			<div
				class="m-2 mb-4"
				v-for="(price, key) in prices.filter(
					el => el.products && el.products.length > 0
				)"
				@click="openDetail({ ...price.products[0], prices: [price] })"
				:key="key"
			>
				<product
					:label="price.products[0].label"
					:prepration-period="price.products[0].preprationPeriod"
					:description="textTruncate(price.products[0].description)"
					:image="price.products[0].image"
					:prices="[
						{
							id: price.id,
							isFavorit: user.likedPrices.includes(price.id),
							price: price.price,
							promotional: price.promoPrice
						}
					]"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Product from '../restaurants/component/Product';
import ProductDetail from '../restaurants/component/ProductDetail';
import { ApiProfileFavoritPrice } from '@/tools/apiService';
import { textTruncate } from '@/tools/helpers';
import { mapGetters } from 'vuex';

export default {
	data: () => ({
		prices: [],
		isDetailOpen: false,
		selectedProduct: null
	}),
	mounted: function() {
		this.getPrices();
	},
	methods: {
		textTruncate,
		openDetail: function(product) {
			this.isDetailOpen = true;
			this.selectedProduct = product;
		},
		getPrices: function() {
			ApiProfileFavoritPrice().then(response => {
				this.prices = response.data.likedPrices;
			});
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		})
	},
	components: {
		Product,
		ProductDetail
	}
};
</script>

<style>
.title-comd {
	font-size: 25px;
	color: #726837;
}
.bg-heart {
	background: #c20016;
	color: #fff;
	font-size: 1rem;
}
</style>
