var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{model:{value:(_vm.isDetailOpen),callback:function ($$v) {_vm.isDetailOpen=$$v},expression:"isDetailOpen"}},[(_vm.selectedProduct)?_c('product-detail',{attrs:{"product":_vm.selectedProduct},on:{"submited":function($event){_vm.isDetailOpen = false}}}):_vm._e()],1),(_vm.prices.length == 0)?_c('div',{staticClass:"order-body p-3"},[_c('div',{staticClass:"p-4 text-grey-500 bg-white text-center shadow rounded"},[_vm._v(" aucun produit ")])]):_vm._e(),_c('div',{staticClass:"order-body p-3"},_vm._l((_vm.prices.filter(
				function (el) { return el.products && el.products.length > 0; }
			)),function(price,key){return _c('div',{key:key,staticClass:"m-2 mb-4",on:{"click":function($event){return _vm.openDetail(Object.assign({}, price.products[0], {prices: [price]}))}}},[_c('product',{attrs:{"label":price.products[0].label,"prepration-period":price.products[0].preprationPeriod,"description":_vm.textTruncate(price.products[0].description),"image":price.products[0].image,"prices":[
					{
						id: price.id,
						isFavorit: _vm.user.likedPrices.includes(price.id),
						price: price.price,
						promotional: price.promoPrice
					}
				]}})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }